@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@200;500&display=swap');

body {
  margin: 0;
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #000;
  background-color: #282828;
}

.container {
  max-width: 1170px;
  width: calc(100% - 30px);
  padding: 0 15px;
  margin: 0 auto;
}

.scroll_to_top_btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border: 3px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #282828;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.scroll_to_top_btn img {
  width: 24px;
  height: 24px;
  display: block;
}

.not_found_wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not_found {
  position: relative;
  padding: 15px;
}

.not_found img {
  display: block;
  width: 490px;
}

.not_found_text {
  position: absolute;
  width: 230px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 21px;
  top: 159px;
  text-align: center;
  padding: 10px;
}

.not_found_text p {
  margin: 0 0 15px;
}

.section_link {
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  text-decoration: none;
  font-weight: bold;
  position: relative;
}

.section_link img {
  display: block;
  width: 15px;
  position: absolute;
  left: 40px;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.section_link span {
  transition: all .3s ease-in-out;
  display: block;
}

.section_link:hover span {
  transform: translateX(10px);
}

.section_link:hover img {
  opacity: 1;
  left: 25px;
}

.section_title {
  text-align: center;
  margin: 0 0 25px;
  font-size: 30px;
  text-transform: uppercase;
}

.header {
  padding: 15px 0;
  display: flex;
  align-items: center;
  position: relative;
}

.header::after {
  position: absolute;
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.logo {
  width: 100px;
  display: block;
}

.header_socials_wrap {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.header_socials_wrap img {
  display: block;
  width: 30px;
}

.header_socials_wrap a {
  display: block;
  transition: all .2s ease-in-out;
  margin-left: 30px;
}

.header_socials_wrap a:hover {
  transform: translateY(-2px);
} 

.main_section,
.contacts_section {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main_section_content {
  display: flex;
  padding: 30px 0 0;
}

.main_section_img {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.main_section_img img {
  display: block;
  width: 380px;
}

.main_section_text {
  text-align: right;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 30px;
}

.main_section_title {
  font-family: 'Urbanist', sans-serif;
  font-weight: 200;
  font-size: 100px;
  line-height: 1;
  margin: 0 0 25px;
  color: #ec5294;
  text-shadow: 0px 0px 10px rgba(236, 82, 148, 1);
}

.main_section_descr {
  color: #fff;
  line-height: 1.75;
  margin: 0 0 25px;
}

.contacts_section {
  overflow: hidden;
}

.constacts_section_content {
  display: flex;
  padding-top: 30px;
}

.contacts_title {
  text-align: center;
  margin: 0 0 25px;
  font-size: 42px;
  text-transform: uppercase;
}

.contacts_section_img {
  width: 50%;
  margin-bottom: -2px;
}

.contacts_section_img img {
  display: block;
  width: 420px;
  margin: 0 auto;
}

.contacts_block {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contacts_list {
  margin: 0;
  padding: 0;
}

.contacts_list li {
  list-style-type: none;
  position: relative;
}

.contacts_list li::after {
  transition: all .2s ease-in-out;
  position: absolute;
  content: '-';
  top: 10px;
  left: -10px;
  font-size: 20px;
  font-weight: bold;
}

.contacts_list li:hover::after {
  left: -15px;
}

.contacts_list a {
  display: block;
  padding: 10px;
  text-decoration: none;
  font-size: 20px;
  color: #000;
}

.catalog_section {
  background-color: #697be7;
  padding: 40px 0;
}

.year_select_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.year_select_wrap p {
  margin-right: 10px;
}

.year_select {
  width: 100px;
}

.catalog_list_block {
  margin-bottom: 40px;
}

.catalog_list_block_title {
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 40px;
}

.catalog_list_block_title::after {
  position: absolute;
  content: '';
  width: 150px;
  height: 2px;
  background-color: #000;
  bottom: -10px;
  left: 0;
}

.catalog_list_block ul {
  margin: 0;
  padding: 0 0 0 100px;
  list-style-type: none;
}

.catalog_list_block ul li {
  margin-bottom: 20px;
}

.catalog_list_item_links {
  font-size: 14px;
  margin-top: 5px;
}

.catalog_list_item_links:empty {
  display: none;
}

.catalog_list_item_links a {
  margin-right: 15px;
  text-decoration: none;
  color: #ddd;
  transition: all .2s ease-in-out;
}

.catalog_list_item_links a:hover {
  color: #f095ff;
}

.footer {
  background-color: #000;
  color: #fff;
}

.footer_text {
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
}

@media (max-width: 1199px) {
  .main_section_img img {
    width: 300px;
  }

  .main_section_title {
    font-size: 90px;
  }
}

@media (max-width: 1023px) {
  .main_section_img img {
    width: 250px;
  }

  .main_section_title {
    font-size: 70px;
  }

  .main_section_descr {
    line-height: 1.5;
  }

  .contacts_section_img img {
    width: 320px;
  }
}

@media (max-width: 767px) {
  .main_section_content,
  .constacts_section_content {
    flex-direction: column-reverse;
  }

  .main_section_img,
  .main_section_text,
  .contacts_section_img,
  .contacts_block {
    width: 100%;
  }

  .main_section_text {
    text-align: center;
    align-items: center;
  }

  .contacts_title {
    font-size: 36px;
  }

  .contacts_block {
    margin-bottom: 30px;
  }

  .contacts_section_img img {
    width: 300px;
  }
}

@media (max-width: 540px) {
  .logo {
    width: 75px;
  }

  .catalog_section {
    padding: 25px 0;
  }

  .section_title {
    font-size: 24px;
  }

  .catalog_list_block ul {
    padding: 0 0 0 50px;
  }

  .scroll_to_top_btn {
    right: 25px;
    bottom: 40px;
  }

  .not_found img {
    width: 370px;
  }

  .not_found_text {
    width: 169px;
    height: 116px;
    right: 20px;
    top: 124px;
  }
}

@media (max-width: 414px) {
  .main_section_title {
    font-size: 56px;
    font-weight: 500;
  }

  .main_section_img img {
    width: 200px;
  }

  .contacts_title {
    font-size: 30px;
  }

  .contacts_list a {
    font-size: 18px;
  }

  .contacts_list li::after {
    top: 8px;
  }

  .contacts_section_img img {
    width: 250px;
  }

  .header_socials_wrap a {
    margin-left: 25px;
  }

  .not_found img {
    width: 315px;
  }

  .not_found_text {
    width: 141px;
    height: 95px;
    right: 19px;
    top: 108px;
    font-size: 14px;
  }

  .not_found_text p {
    margin-bottom: 10px;
  }
}

@media (max-width: 350px) {
  .logo {
    width: 50px;
  }

  .catalog_list_block ul {
    padding: 0 0 0 30px;
  }

  .not_found img {
    width: 285px;
  }

  .not_found_text {
    width: 125px;
    height: 85px;
    right: 19px;
    top: 99px;
    font-size: 13px;
  }

  .not_found_text p {
    margin-bottom: 5px;
  }
}

@media (max-width: 319px) {
  .header_socials_wrap a {
    margin-left: 20px;
  }

  .main_section_title {
    font-size: 48px;
  }

  .main_section_descr {
    font-size: 15px;
  }

  .contacts_title {
    font-size: 26px;
  }

  .not_found img {
    width: 245px;
  }

  .not_found_text {
    width: 105px;
    height: 70px;
    right: 18px;
    top: 87px;
    font-size: 11px;
  }
}